import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import { base, themed } from './base';

const ImageWrapper = styled('img')(
  {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '100%',
  },
  base,
  themed('Image')
);

const Image = ({ src, alt, fluid, fixed, fadeIn, style, imageObj, ...props }) =>
  fluid || fixed || (imageObj && imageObj.extension !== 'svg') ? (
    <GatsbyImage
      style={style}
      fluid={fluid || imageObj?.childImageSharp.fluid}
      fixed={fixed || imageObj?.childImageSharp.fixed}
      fadeIn={fadeIn}
      alt={alt}
      {...props}
    />
  ) : (
    <ImageWrapper src={src || imageObj.publicURL} alt={alt} {...props} />
  );

export default Image;

Image.propTypes = {
  alt: PropTypes.string.isRequired,
};

Image.defaultProps = {
  m: 0,
  src: null,
  fluid: null,
  fixed: null,
  imageObj: null,
  fadeIn: false,
  style: { display: 'block', maxWidth: '100%', height: 'auto' },
};
