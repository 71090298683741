import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import NavbarWrapper from 'common/src/components/Navbar';
import Drawer from 'common/src/components/Drawer';
import Box from 'common/src/components/Box';
import HamburgMenu from 'common/src/components/HamburgMenu';
import Container from 'common/src/components/UI/NavbarContainer';
import { DrawerContext } from 'common/src/contexts/DrawerContext';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Image from 'common/src/components/Image';

const NavbarTemplate = ({
  navbarStyle,
  logoStyle,
  row,
  menuWrapper,
  menuItems,
  returnHome,
  logo,
}) => {
  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container>
        <Box {...row}>
          {returnHome ? (
            <Link to="/" state={{ scrollTo: 'banner_section' }}>
              <Image
                imageObj={logo}
                title="eID"
                alt="eID"
                style={logoStyle}
                loading="eager"
              />
            </Link>
          ) : (
            <AnchorLink href="#banner_section" offset={70}>
              <Image
                imageObj={logo}
                title="eID"
                alt="eID"
                style={logoStyle}
                loading="eager"
              />
            </AnchorLink>
          )}

          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className="main_menu"
              menuItems={menuItems}
              returnHome={returnHome}
              offset={-70}
            />
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#eb4d4b" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={menuItems}
                returnHome={returnHome}
                drawerClose={true}
                offset={-100}
              />
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

NavbarTemplate.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

NavbarTemplate.defaultProps = {
  navbarStyle: {
    className: 'hosting_navbar',
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  logoStyle: {
    width: '200px',
    mr: [0, 0, 0, '15px'],
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'primaryWithBg',
    minHeight: 'auto',
    height: `${1}`,
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
  },
};

export default ({ returnHome }) => {
  const data = useStaticQuery(graphql`
    query Navbar {
      markdownRemark(frontmatter: { templateKey: { eq: "Navbar" } }) {
        frontmatter {
          logo {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
            extension
            publicURL
          }
          menuItems {
            label
            path
            offset
          }
        }
      }
    }
  `);

  const { frontmatter } = data.markdownRemark;
  return (
    <NavbarTemplate
      menuItems={frontmatter.menuItems}
      logo={frontmatter.logo}
      returnHome={returnHome}
    />
  );
};
